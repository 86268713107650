let request: IDBOpenDBRequest;
let db: IDBDatabase;
let version = 3;
let dbName = 'ssDB';

export interface Images {
    fileId: string;
    height: number;
    width: number;
    blob: string; //Base64
    alt: string;
}

export enum Stores {
    Images = 'images',
}

export const initDB = (): Promise<boolean | IDBDatabase> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        // if the data object store doesn't exist, create it
        request.onupgradeneeded = (e) => {
            //@ts-ignore
            db = e.target.result;

            if (!db.objectStoreNames.contains(Stores.Images)) {
                // console.log('Creating users store');
                db.createObjectStore(Stores.Images, { keyPath: 'fileId' });
            }
            // no need to resolve here
        };

        request.onsuccess = (e) => {
            //@ts-ignore
            db = e.target.result;
            // db = request.result;
            // get current version and store it
            version = db.version;
            // resolve(request.result);
            //@ts-ignore
            resolve(e.target.result);
        };

        request.onerror = (e) => {
            resolve(false);
        };
    });
};

export const addData = <T>(storeName: string, data: T): Promise<T | string | null> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        request.onsuccess = async (e) => {
            // console.log('request.onsuccess - addData', data);
            //@ts-ignore
            db = e.target.result;
            // db = event?.target?.result;
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            store.add(data);
            resolve(data);
        };

        request.onerror = () => {
            const error = request.error?.message
            if (error) {
                resolve(error);
            } else {
                resolve('Unknown error');
            }
        };
    });
};

export const deleteData = (storeName: string, key: string): Promise<boolean> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        request.onsuccess = () => {
            // console.log('request.onsuccess - deleteData', key);
            //@ts-ignore
            db = e.target.result;

            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const res = store.delete(key);
            res.onsuccess = () => {
                resolve(true);
            };
            res.onerror = () => {
                resolve(false);
            }
        };
    });
};

export const updateData = <T>(storeName: string, key: string, data: T): Promise<T | string | null> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName, version);

        request.onsuccess = () => {
            // console.log('request.onsuccess - updateData', key);
            //@ts-ignore
            db = e.target.result;

            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            const res = store.get(key);
            res.onsuccess = () => {
                const newData = { ...res.result, ...data };
                store.put(newData);
                resolve(newData);
            };
            res.onerror = () => {
                resolve(null);
            }
        };
    });
};

export const getStoreData = <T>(storeName: Stores): Promise<T[]> => {
    return new Promise((resolve) => {
        request = indexedDB.open(dbName);

        request.onsuccess = (e) => {
            // console.log('request.onsuccess - getAllData');
            //@ts-ignore
            db = e.target.result;
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            const res = store.getAll();
            res.onsuccess = () => {
                resolve(res.result);
            };
        };
    });
};

export { };