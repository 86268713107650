import { getPhotoTitle } from "./PhotoRandomTitle";
import images from "./images.json";
// import imagesWithURL from "./ImagesWithURL.json";
// import imagesWithThumbURL from './imagesWithThumbURL.json'
// import { Images, Stores, getStoreData } from "./IndexedDB/db";
export interface PhotosModel {
  idx: number;
  id: string;
  width: number;
  height: number;
}

export interface Photo {
  fileName: string;
  src: string;
  thumbSrc: string;
  /** Image width in pixels. */
  width: number;
  /** Image height in pixels. */
  height: number;

  key?: string;
  /** Optional image `alt` attribute. */
  alt: string;
  /** Optional image `title` attribute. */
  title: string;
}

export function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
export const usePhotos = () => {
  ////////Start - Test code to generate file name and size
  // const unsplashPhotos: { id: string; width: number; height: number }[] = [];
  // const unsplashPhotos1: {
  //   idx: number;
  //   id: string;
  //   width: number;
  //   height: number;
  // }[] = [];
  // const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

  // const unsplashLink = (name: string) => imagesWithURL.imagesWithURL.find(s => s.id === name)?.url;
  // const thumbLink = (name: string) => imagesWithThumbURL.imagesWithThumbURL.find(s => s.id === name)?.url;
  //https://supti.net/img/File%2063.jpg

  // const unsplashLink = (name: string) => `https://supti.net/img/${name}`;
  // const thumbLink = (name: string) => `https://supti.net/img/thumb/${name}`;

  const unsplashLink = (name: string) => `/img/${name}`;
  const thumbLink = (name: string) => `/img/thumb/${name}`;
  let photos: Photo[] = [];

  // const getImageDimenstion = (index: number, fileName: string) => {
  //   let img = new Image();
  //   let width = 0;
  //   let height = 0;
  //   const url = unsplashLink(fileName);
  //   img.src = url;

  //   img.onload = function (event) {
  //     let loadedImage = event.currentTarget as HTMLImageElement;
  //     width = loadedImage.width;
  //     height = loadedImage.height;
  //     unsplashPhotos1.push({
  //       idx: index,
  //       id: fileName,
  //       width: width,
  //       height: height,
  //     });
  //     const unsplashPhotos2 = unsplashPhotos1.sort((n1, n2) => {
  //       if (n1.idx > n2.idx) {
  //         return 1;
  //       }
  //       if (n1.idx < n2.idx) {
  //         return -1;
  //       }
  //       return 0;
  //     });
  //     console.clear();
  //     console.log(unsplashPhotos2);
  //   };

  //   return [height, width];
  // };
  // for (let index = 1; index < 250; index++) {
  //   const fileName = `File ${index}.jpg`;
  //   getImageDimenstion(index, fileName);
  // }
  ////////END - Test code to generate file name and size
  const unsplashPhotosAll = shuffleArray(images.images);
  const unsplashPhotos = unsplashPhotosAll;//.slice(0, 1);

  photos = unsplashPhotos.map(
    (photo, idx) =>
    ({
      fileName: photo.id,
      src: unsplashLink(photo.id),
      width: photo.width,
      height: photo.height,
      title: getPhotoTitle(idx),
      thumbSrc: thumbLink(photo.id),
    } as Photo)
  );
  // const photosAll = unsplashPhotosAll.map(
  //   (photo, idx) =>
  //     ({
  //       src: unsplashLink(photo.id),
  //       width: photo.width,
  //       height: photo.height,
  //       title: getPhotoTitle(idx),
  //       srcSet: breakpoints.map((breakpoint) => {
  //         const height = Math.round((photo.height / photo.width) * breakpoint);
  //         return {
  //           src: unsplashLink(photo.id),
  //           width: breakpoint,
  //           height,
  //         };
  //       }),
  //     } as Photo)
  // );
  return photos;
};
